<template>
  <ShowView
    :module="module"
    :model="model"
    :show-item="showItem"
    :view-config="viewConfig"
    :fetch="fetch"
    :data-table-data="dataTableData"
    :namespace="'administration/partner'"
  />
</template>

<script>
import ShowView from '@/global/components/Show'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('administration/partner')
export default {
  name: 'PartnerView',

  components: {
    ShowView
  },

  props: {
    module: {
      type: String,
      default: 'administration'
    },
    model: {
      type: String,
      default: 'partner'
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters(['showItem', 'viewConfig', 'locations', 'locationsViewConfig']),
    dataTableData () {
      return {
        locations: {
          resources: this.locations,
          viewConfig: this.locationsViewConfig,
          fetch: this.fetchLocations
        }
      }
    }
  },

  created () {
    const partnerId = this.$route.params.id
    this.fetch(partnerId)
    this.fetchLocations(partnerId)
  },

  async updated () {
  },

  methods: {
    ...mapActions(['fetch', 'fetchLocations'])
  }
}
</script>
